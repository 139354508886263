<template>
  <div class="profile-corner">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click="openModal"
          :class="className"
        >
          <font-awesome-icon icon="pen" />
        </div>
      </template>
      <span>Modifier les statuts</span>
    </v-tooltip>
    <!-- Validation statue dossier Modal -->
    <v-dialog
      v-model="dialog"
      max-width="600px"
      scrollable
      :persistent="true"
      :hide-overlay="true"
      content-class="custom-vuetify-dialog-header"
    >
      <v-card class="custom-dialog">
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header"
            >Modification globale de statut
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card mt-3">
          <v-select
            class="mt-2 mb-4"
            :clearable="true"
            v-model="currentStatut"
            :items="[
              { text: 'Facture à éditer', value: 'Facture à éditer' },
              { text: 'PAYÉ', value: 'paye' },
              { text: 'ADP', value: 'non paye' }
            ]"
            @channge="error = null"
            dense
            outlined
            hide-details
            item-color="#704ad1"
            color="#704ad1"
            label="Statut"
            :persistent-placeholder="true"
            placeholder="Sélectionnez"
            no-data-text="Aucun élément trouvé"
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
          </v-select>
          <v-menu
            v-if="currentStatut == 'paye'"
            :close-on-content-click="false"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                label="Date de paiement"
                outlined
                readonly
                hide-details
                clearable
                @click:clear="handleDateTimePicker(null)"
                @blur="date_debut = parseDate(dateFormatted)"
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                color="#704ad1"
                class="date-analyse-validation-text"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="dateArr"
              @input="handleDateTimePicker($event)"
              no-title
              locale="fr"
              color="#704ad1"
              item-color="#704ad1"
              class="date-analyse-validation"
            >
            </v-date-picker>
          </v-menu>
        </v-card-text>
        <div v-if="error" class="message-error-modal">
          <ul v-if="Array.isArray(error)" class="mb-0">
            <li v-for="(e, index) in error" :key="index">
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ error }}</div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleChangeStatue"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Générer
          </v-btn>
          <v-btn text @click="hideModal">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    className: {
      default: ''
    },
    grouped: {
      default: null
    }
  },
  data() {
    return {
      currentStatut: 'paye',
      dateArr: null,
      loading: false,
      error: null,
      dialog: false,
      dateFormatted: null
    };
  },
  computed: {
    ...mapGetters([, 'getDataTh']),
    computedRows() {
      if (!this.getDataTh) {
        return [];
      }
      return this.getDataTh;
    },
    computedCheckAll() {
      let tab = this.computedRows.filter(item => item.check == true);
      if (tab.length) {
        return true;
      }
      return false;
    }
  },
  watch: {
    dateArr(val) {
      this.dateFormatted = this.formatDate(val);
    }
  },
  methods: {
    ...mapActions(['changeStatutInAnalyseValidation']),
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('-');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    openModal() {
      this.dialog = true;
    },
    handleDateTimePicker(data) {
      this.dateArr = data;
    },
    async handleChangeStatue() {
      if (this.dateArr == null) {
        this.error = 'Date obligatoire';
        return;
      }
      let textStatue =
        this.currentStatut == 'paye'
          ? 'PAYÉ'
          : this.currentStatut == 'non paye'
          ? 'ADP'
          : this.currentStatut;
      let statut =
        'Êtes-vous sur de vouloir attribue le statut ' +
        "'" +
        textStatue +
        "'" +
        ' pour tous les dossiers sélectionnés  ?';
      // let flatpickrInstance;
      // const flatpickr = require('flatpickr').default;
      this.$swal.fire({
        title: statut,
        type: 'info',
        icon: 'info',
        locale: 'fr',
        confirmButtonClass: 'btn btn-success-alert',
        cancelButtonClass: 'btn btn-danger-alert',
        allowOutsideClick: () => !this.$swal.isLoading(),
        stopKeydownPropagation: false,

        preConfirm: async () => {
          this.dialog = false;
          const responce = await this.changeStatutInAnalyseValidation({
            statut: this.currentStatut,
            date: this.dateArr,
            grouped: this.grouped
          });
          this.resetModal();
          if (responce && responce.succes) {
            // this.currentStatut = null;
            this.checkAll = false;
            let textSucces =
              '<div class="mb-2"><b>Les statuts modifiés avec succes:' +
              responce.data.success.length +
              '</b></div>';
            if (responce.data.success.length > 0) {
              textSucces = textSucces + '<ul class="ml-4">';
              for (let i = 0; i < responce.data.success.length; i++) {
                textSucces =
                  textSucces + '<li>' + responce.data.success[i] + '</li>';
              }
              textSucces = textSucces + '</ul>';
            }
            let textFailed =
              '<div class="mb-2"><b> Les statuts non modifiés: ' +
              responce.data.errors.length +
              '</b></div> <div></div>';

            if (responce.data.errors.length > 0) {
              textFailed = textFailed + '<ul class="ml-4">';
              for (let j = 0; j < responce.data.errors.length; j++) {
                textFailed =
                  textFailed + '<li>' + responce.data.errors[j] + '</li>';
              }
              textFailed = textFailed + '</ul>';
            }
            this.$swal({
              icon: 'info',
              html:
                '<blod><div>' +
                textSucces +
                '</div><div></div>' +
                textFailed +
                ' <div></div></blod>',
              confirmButtonText: 'OK !',
              confirmButtonClass: 'btn btn-success-alert',
              cancelButtonClass: 'btn btn-danger-alert',
              preConfirm: async () => {
                this.$emit('refreshData');
              }
            });
          }
        }
        // willOpen: () => {
        //   if (this.currentStatut == 'paye') {
        //     flatpickrInstance = flatpickr('#myDatePicker', {
        //       dateFormat: 'm/d/Y',
        //       format: 'm/d/Y'
        //     });
        //   }
        // }
      });
    },
    hideModal() {
      this.dialog = false;
      this.resetModal();
    },
    resetModal() {
      this.currentStatut = 'paye';
      this.date = null;
      this.error = null;
      this.loading = false;
    }
  },
  components: {},
  mounted() {
    let now = new Date();
    this.dateArr = moment(now).format('YYYY-MM-DD');
  }
};
</script>
<style lang="scss">
// .dialog-analyse-validation {
.v-menu__content {
  // box-shadow: none !important;
  // min-width: 200px !important;
}
// }
</style>
